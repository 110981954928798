
  import { mapState, mapGetters, mapActions } from 'vuex'
  import cloneDeep from 'lodash.clonedeep'

  import enforceTrailingSlash from '~/lib/enforce-trailing-slash'
  import formatSeoHeadForPage from '~/lib/format-seo-head-for-page'
  import getDatoData from '~/lib/get-dato-page-data'
  import notFoundError from '~/lib/errors/not-found'
  import transformCampaignPageData from '~/lib/transform-campaign-page-data'

  export default {
    name: 'Homepage',
    async asyncData(context) {
      try {
        const data = await getDatoData(context).then(cloneDeep)

        if (!data?.home) {
          return context.error(notFoundError)
        }

        const seo = data.home.seo
        const campaignPage = data.home.activeCampaignPage
        const seoTextFirstColumn = data.home.seoTextFirstColumn
        const seoTextSecondColumn = data.home.seoTextSecondColumn
        const showBlackWeekTheme = data.home.showBlackWeekTheme

        // Warning value of campaignData is updated inside transformCampaignPageData
        await transformCampaignPageData(context, campaignPage)

        let customerGroupsSpecificCampaigns = {}

        await Promise.all(data.home.customerGroupsSpecificCampaigns.map(async customerGroupsSpecificCampaign => {
          await transformCampaignPageData(context, customerGroupsSpecificCampaign.activeCampaignPage)
          customerGroupsSpecificCampaigns[customerGroupsSpecificCampaign.customerGroupId] = customerGroupsSpecificCampaign.activeCampaignPage
        }))

        return { campaignPage, seo, seoTextFirstColumn, seoTextSecondColumn, customerGroupsSpecificCampaigns, showBlackWeekTheme }
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(`Error while generating home page:`, error)
        }

        return context.error(notFoundError)
      }
    },
    data() {
      return {
        campaignPage: null,
        seo: null,
        seoTextFirstColumn: null,
        seoTextSecondColumn: null,
        customerGroupsSpecificCampaigns: null,
        showBlackWeekTheme: false,
      }
    },
    head() {
      const seoHead = formatSeoHeadForPage(this.seo, this.$route)
      const defaultLocale = this.$staticDataService.cmsApp?.defaultLocale
      const alternateLinks = this.$staticDataService.cmsApp?.locales
        .map(locale => {
          return {
            href: enforceTrailingSlash(`${process.env.baseUrl}/${locale.code}`),
            rel: 'alternate',
            hreflang: locale.code,
          }
        })

      return Object.assign(seoHead, {
        link: [
          {
            hid: 'canonical-homepage',
            rel: 'canonical',
            href: enforceTrailingSlash(`${process.env.baseUrl}/${this.$i18n.locale}`),
          },
          ...alternateLinks,
          {
            href: enforceTrailingSlash(`${process.env.baseUrl}/${defaultLocale}`),
            rel: 'alternate',
            hreflang: 'x-default',
          },
        ],
      })
    },
    computed: {
      ...mapState({
        customerGroupId: state => state.user.customerGroupId,
      }),
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
      customerGroupsSpecificCampaignPage() {
        return this.customerGroupsSpecificCampaigns?.[this.customerGroupId]
      },
    },
    async mounted() {
      // Ugly black week hack
      if (this.showBlackWeekTheme) {
        document.body.classList.add('black-week')
      }

      this.setShowChatButton({ showChatButton: false })
    },
    beforeDestroy() {
      // Ugly black week hack
      document.body.classList.remove('black-week')

      this.setShowChatButton({ showChatButton: true })
    },
    methods: {
      ...mapActions({
        setShowChatButton: 'setShowChatButton',
      }),
    },
  }
