import { render, staticRenderFns } from "./index.vue?vue&type=template&id=89d82662"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=89d82662&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CampaignPage: require('/opt/build/repo/src/client/components/campaign-page/campaign-page.vue').default,TextAccordion: require('/opt/build/repo/src/client/components/text-accordion/text-accordion.vue').default})
